@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Allianz_Neo/eot/AllianzNeoW04-Light.eot'); /* IE9 Compat Modes */
  src:
    /* IE6-IE8 */ url('../fonts/Allianz_Neo/eot/AllianzNeoW04-Light.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */ url('../fonts/Allianz_Neo/woff2/AllianzNeoW04-Light.woff2') format('woff2'),
    /* Modern Browsers */ url('../fonts/Allianz_Neo/woff/AllianzNeoW04-Light.woff') format('woff'),
    /* Safari, Android, iOS */ url('../fonts/Allianz_Neo/ttf/AllianzNeoW04-Light.ttf') format('truetype'),
    /* Legacy iOS */ url('../fonts/Allianz_Neo/svg/AllianzNeoW04-Light.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Allianz_Neo/eot/AllianzNeoW04-Regular.eot'); /* IE9 Compat Modes */
  src:
    /* IE6-IE8 */ url('../fonts/Allianz_Neo/eot/AllianzNeoW04-Regular.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */ url('../fonts/Allianz_Neo/woff2/AllianzNeoW04-Regular.woff2') format('woff2'),
    /* Modern Browsers */ url('../fonts/Allianz_Neo/woff/AllianzNeoW04-Regular.woff') format('woff'),
    /* Safari, Android, iOS */ url('../fonts/Allianz_Neo/ttf/AllianzNeoW04-Regular.ttf') format('truetype'),
    /* Legacy iOS */ url('../fonts/Allianz_Neo/svg/AllianzNeoW04-Regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Allianz_Neo/eot/AllianzNeoW04-Italic.eot'); /* IE9 Compat Modes */
  src:
    /* IE6-IE8 */ url('../fonts/Allianz_Neo/eot/AllianzNeoW04-Italic.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */ url('../fonts/Allianz_Neo/woff2/AllianzNeoW04-Italic.woff2') format('woff2'),
    /* Modern Browsers */ url('../fonts/Allianz_Neo/woff/AllianzNeoW04-Italic.woff') format('woff'),
    /* Safari, Android, iOS */ url('../fonts/Allianz_Neo/ttf/AllianzNeoW04-Italic.ttf') format('truetype'),
    /* Legacy iOS */ url('../fonts/Allianz_Neo/svg/AllianzNeoW04-Italic.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Allianz_Neo/eot/AllianzNeoW04-SemiBold.eot'); /* IE9 Compat Modes */
  src:
    /* IE6-IE8 */ url('../fonts/Allianz_Neo/eot/AllianzNeoW04-SemiBold.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */ url('../fonts/Allianz_Neo/woff2/AllianzNeoW04-SemiBold.woff2') format('woff2'),
    /* Modern Browsers */ url('../fonts/Allianz_Neo/woff/AllianzNeoW04-SemiBold.woff') format('woff'),
    /* Safari, Android, iOS */ url('../fonts/Allianz_Neo/ttf/AllianzNeoW04-SemiBold.ttf') format('truetype'),
    /* Legacy iOS */ url('../fonts/Allianz_Neo/svg/AllianzNeoW04-SemiBold.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Allianz_Neo/eot/AllianzNeoW04-Bold.eot'); /* IE9 Compat Modes */
  src:
    /* IE6-IE8 */ url('../fonts/Allianz_Neo/eot/AllianzNeoW04-Bold.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */ url('../fonts/Allianz_Neo/woff2/AllianzNeoW04-Bold.woff2') format('woff2'),
    /* Modern Browsers */ url('../fonts/Allianz_Neo/woff/AllianzNeoW04-Bold.woff') format('woff'),
    /* Safari, Android, iOS */ url('../fonts/Allianz_Neo/ttf/AllianzNeoW04-Bold.ttf') format('truetype'),
    /* Legacy iOS */ url('../fonts/Allianz_Neo/svg/AllianzNeoW04-Bold.svg#OpenSans') format('svg');
}
