:root {
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  color: #414141;
  font-family: 'Allianz Neo', sans-serif;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'Allianz Neo', sans-serif;
}

p {
  margin: 0;
}

a {
  color: inherit;
  -webkit-filter: none;
  filter: none;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  display: block;
  padding: 0;
  transition: all 0.1s;
}
button:focus {
  opacity: 0.75;
  outline: none;
}
