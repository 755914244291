:root {
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: #414141;
  font-family: 'Allianz Neo', sans-serif;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'Allianz Neo', sans-serif;
}

p {
  margin: 0;
}

a {
  color: inherit;
  -webkit-filter: none;
  filter: none;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  display: block;
  padding: 0;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
button:focus {
  opacity: 0.75;
  outline: none;
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/AllianzNeoW04-Light.d222e734.eot); /* IE9 Compat Modes */
  src:
    /* IE6-IE8 */ url(/static/media/AllianzNeoW04-Light.d222e734.eot?#iefix) format('embedded-opentype'),
     url(/static/media/AllianzNeoW04-Light.9c08effc.woff2) format('woff2'),
     url(/static/media/AllianzNeoW04-Light.d0cda45d.woff) format('woff'),
     url(/static/media/AllianzNeoW04-Light.33a5d3d6.ttf) format('truetype'),
     url(/static/media/AllianzNeoW04-Light.2ba3a00e.svg#OpenSans) format('svg');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/AllianzNeoW04-Regular.8b7f6511.eot); /* IE9 Compat Modes */
  src:
    /* IE6-IE8 */ url(/static/media/AllianzNeoW04-Regular.8b7f6511.eot?#iefix) format('embedded-opentype'),
     url(/static/media/AllianzNeoW04-Regular.a9b6cb15.woff2) format('woff2'),
     url(/static/media/AllianzNeoW04-Regular.2dd53785.woff) format('woff'),
     url(/static/media/AllianzNeoW04-Regular.54d88bc0.ttf) format('truetype'),
     url(/static/media/AllianzNeoW04-Regular.5feaf0b5.svg#OpenSans) format('svg');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/AllianzNeoW04-Italic.3710ab4c.eot); /* IE9 Compat Modes */
  src:
    /* IE6-IE8 */ url(/static/media/AllianzNeoW04-Italic.3710ab4c.eot?#iefix) format('embedded-opentype'),
     url(/static/media/AllianzNeoW04-Italic.9261b538.woff2) format('woff2'),
     url(/static/media/AllianzNeoW04-Italic.e2f8eb3f.woff) format('woff'),
     url(/static/media/AllianzNeoW04-Italic.22d33625.ttf) format('truetype'),
     url(/static/media/AllianzNeoW04-Italic.b279f11a.svg#OpenSans) format('svg');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/AllianzNeoW04-SemiBold.cb29f7b2.eot); /* IE9 Compat Modes */
  src:
    /* IE6-IE8 */ url(/static/media/AllianzNeoW04-SemiBold.cb29f7b2.eot?#iefix) format('embedded-opentype'),
     url(/static/media/AllianzNeoW04-SemiBold.f9f0c55e.woff2) format('woff2'),
     url(/static/media/AllianzNeoW04-SemiBold.db036489.woff) format('woff'),
     url(/static/media/AllianzNeoW04-SemiBold.1865024a.ttf) format('truetype'),
     url(/static/media/AllianzNeoW04-SemiBold.75400967.svg#OpenSans) format('svg');
}

@font-face {
  font-family: 'Allianz Neo';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/AllianzNeoW04-Bold.11de15be.eot); /* IE9 Compat Modes */
  src:
    /* IE6-IE8 */ url(/static/media/AllianzNeoW04-Bold.11de15be.eot?#iefix) format('embedded-opentype'),
     url(/static/media/AllianzNeoW04-Bold.5d398855.woff2) format('woff2'),
     url(/static/media/AllianzNeoW04-Bold.7af50fd1.woff) format('woff'),
     url(/static/media/AllianzNeoW04-Bold.cadf394e.ttf) format('truetype'),
     url(/static/media/AllianzNeoW04-Bold.8946e148.svg#OpenSans) format('svg');
}

